@import './colors';

html,
body {
  background: $background-color;
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
  overflow: hidden;
  color: $white;
}

.flex-spacer {
  flex: 1 1 auto;
}
